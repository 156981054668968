/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

body,
html {
  overflow-x: hidden !important;
}
body {
  margin: 0;
  padding: 0 !important ;
  -webkit-font-smoothing: antialiased;
  background-color: #fff !important;
}
.mt-minus10 {
  margin-top: -10px;
}
.Mui-error.MuiFormHelperText-root {
  display: table;
  position: absolute;
  /*bottom: -1.2rem;*/
  line-height: 1.2;
  bottom: inherit;
  top: 2.3rem;
}

.covernote_error .Mui-error.MuiFormHelperText-root {
  position: absolute;
  top: inherit;
  bottom: -1rem;
  right: 0;
}
.dueDate_error .Mui-error.MuiFormHelperText-root {
  position: absolute;
  top: inherit;
  bottom: -1.3rem;
  right: 0;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.border-box {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: -6px;
  margin-bottom: 10px;
}
.progress-bar-large {
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 1px 6px rgba(153, 120, 120, 0.4);
}
.progress-bar-large > div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  overflow: hidden;
  height: 25px;
  background-color: #f2f2f2;
  font-weight: 700;
}
.progress-bar-large > div .progress {
  border-radius: 100px;
  position: absolute;
  height: 100%;
  background-color: #6bff6b;
  left: 0;
  transition: all 0.5s;
  top: 0;
}
.progress-bar-large > div .count {
  z-index: 1;
}
.progress-bar-large.small {
  box-shadow: none;
  padding: 0;
  font-size: 14px;
  text-transform: uppercase;
}
.progress-bar-large.small > div {
  height: 35px;
}
.hidden-input {
  height: 0;
  font-size: 0;
  width: 0;
  overflow: hidden;
  border: 0;
  display: none;
}
.green-clr {
  background-color: #28a745;
}
.page-title {
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}
.linkIcon {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
}
.linkIconPrimary {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  color: #3c0292;
  cursor: pointer;
  text-decoration: none;
}
.linkIcon.disabled {
  color: #c1c1c1;
  cursor: text;
}
.link {
  color: #007bff;
  cursor: pointer;
}
.status-badge {
  border: 1px solid;
  border-radius: 5px;
  padding: 3px 5px 1px;
  min-width: 180px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
}

.rfi-status {
  border-radius: 5px;
  padding: 3px 5px;
  min-width: 110px;
  text-align: center;
  display: flex;
  align-items: center;
  width: 130px;
  text-transform: uppercase;
  font-size: 13px;
}

.RFI_SENT {
  background-color: rgba(123, 192, 255, 1);
  color: #000000;
}

.RFI_RESOLVED {
  background-color: rgba(175, 243, 187, 0.8);
  color: #000000;
}

div.MuiDrawer-root.MuiDrawer-modal > div.MuiBackdrop-root {
  opacity: 0.3 !important;
}

div.MuiDrawer-root.MuiDrawer-modal {
  z-index: 1300 !important;
}

.RFI_OVERDUE {
  background-color: rgba(255, 188, 188, 1);
  color: #000000;
}
.RFI_PENDING {
  background-color: rgba(255, 248, 156, 1);
  color: #000000;
}

.IN_PROGRESS,
.REVIEW_IN_PROGRESS {
  border-color: #ffae6b;
  background-color: #ffae6b;
  color: #000000;
}

.FINAL_REVIEW,
.AUDITOR_REVIEWED,
.SUBMITTED_TO_REGULATOR,
.AVAILABLE {
  border-color: #aff3bb;
  background-color: #aff3bb;
  color: #000000;
}
.ASSIGNED {
  border-color: #7bc0ff;
  background-color: #7bc0ff;
  color: #000000;
}
.SUBMITTED_FOR_REVIEW,
.PENDING_FOR_REVIEW,
.PENDING_REVIEW,
.PENDING_ASSIGNMENT {
  border-color: #fff89c;
  background-color: #fff89c;
  color: #000000;
}
.DRAFT {
  border-color: #fffcd2;
  background-color: #fffcd2;
  color: #000000;
}
.RETURNED {
  border-color: #ffbcbc;
  background-color: #ffbcbc;
  color: #000000;
}
.LOADING {
  border-color: #d9d9d9;
  background-color: #d9d9d9;
  color: #000000;
}
/*Data Table*/
/* .data-table .MuiTableCell-head {
  color: #707070;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 6px 10px 2px;
  background: #eeeeee;
} */
.data-table .MuiTableCell-body {
  padding: 5px 10px;
}
/*pagination*/
.custom-pagination {
  display: inline-flex;
}
.custom-pagination .MuiPaginationItem-root {
  color: #007bff;
}
.custom-pagination .MuiPaginationItem-outlinedPrimary.Mui-selected:hover,
.custom-pagination
  .MuiPaginationItem-outlinedPrimary.Mui-selected.Mui-focusVisible,
.custom-pagination .MuiPaginationItem-outlinedPrimary.Mui-selected {
  background: #124571;
  color: #fff;
}
.custom-pagination .MuiPaginationItem-page.Mui-disabled {
  color: #505050;
}
.custom-pagination .MuiPaginationItem-rounded {
  border-radius: 0;
  margin: 0;
  padding: 0 15px;
  height: 27px;
}
/**/
.stats-bar {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.stats-bar .count {
  font-size: 1.25rem;
  font-weight: 900;
  margin: 0 10px;
}
.custom-stepper {
  text-transform: uppercase;
}
.custom-stepper .MuiStepIcon-root {
  width: 2.5rem;
  height: 2.5rem;
}
.custom-stepper .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 5px;
}
.custom-stepper .MuiStepConnector-alternativeLabel {
  top: 20px;
}
.custom-stepper .MuiStepIcon-root.MuiStepIcon-active {
  color: #124571;
}
.fixed-action-bar {
  position: fixed;
  bottom: 0;
  background: #fff;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  left: 0;
  z-index: 1300;
}
.info-box {
  background: #fffdf2;
  border-radius: 5px;
  border: 1px solid #ffeb87;
  font-size: 12px;
  padding: 10px;
  margin-bottom: 5px;
}
.info-box .MuiSvgIcon-root {
  color: #dcb800;
}
.list-question-margin {
  margin: 11px 0 10px;
}
.option-selection-box {
  border: 1px solid #ccc;
  padding: 0 0px 0 10px;
  margin: 5px 0;
}
.option-selection-box .rating {
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  width: 50px;
  justify-content: center;
  background-color: #f9a3ae;
}
.option-selection-box .rating.highest {
  background-color: #9cf1aa;
}

.badge-tab {
  top: 45%;
  right: -14px;
  background-color: #e93434;
  color: #fff;
}
.colorTag {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 3px;
  vertical-align: bottom;
  margin: 0 5px;
}
.highScoreBgColor {
  background-color: #9cf1aa;
}
.mediumScoreBgColor {
  background-color: #e7e99f;
}
.lowScoreBgColor {
  background-color: #f9a3ae;
}

.side-nav-q .MuiTimelineItem-missingOppositeContent:before {
  display: none;
}

.lightHeading {
  color: #9d9d9d;
  text-transform: uppercase;
}

.assign-assessment-arrow {
  position: absolute;
  left: 95%;
  top: 15px;
  width: 10%;
  text-align: center;
}

.helpText {
  font-size: 12px;
  color: #009746;
}

/* .stepper::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  content: "";
  width: calc(100% - 170px);
  background: #e7e7e7;

} */

.stepper {
  display: flex;
  padding: 0;
  /* width: 60rem; */
  list-style: none;
  position: relative;
  /* margin-left: 10px; */
  height: 100%;
  margin: 0;
}

.stepperItem.current {
  background: #124571;
  font-weight: bold;
}

.stepperItem {
  background: #d1d1d1;
  -webkit-clip-path: polygon(
    20px 50%,
    0% 0%,
    calc(100% - 20px) 0%,
    100% 50%,
    calc(100% - 20px) 100%,
    0% 100%
  );
  clip-path: polygon(
    20px 50%,
    0% 0%,
    calc(100% - 20px) 0%,
    100% 50%,
    calc(100% - 20px) 100%,
    0% 100%
  );
  height: 100%;
  min-width: 60px;
  width: 100%;
}

.stepperItem.complete {
  background: #d1d1d1;
}

.stepperItem:first-child {
  /* padding: 20px; */
  -webkit-clip-path: polygon(
    0% 0%,
    calc(100% - 20px) 0%,
    100% 50%,
    calc(100% - 20px) 100%,
    0% 100%
  );
  clip-path: polygon(
    0% 0%,
    calc(100% - 20px) 0%,
    100% 50%,
    calc(100% - 20px) 100%,
    0% 100%
  );
}

body > [role="presentation"] ~ *:last-child {
  z-index: 9999;
}
