.css-gauge {
  position: relative;
  font-weight: bold;
  font-size: 34px;
  border-left: 1px solid;
  border-top: 1px solid;
  border-right: 1px solid;
  border-radius: 50%;
  border-color: #bfbfbf;
  border-bottom: 1px solid transparent;
}
.css-gauge .css-gauge-needle {
  /* background: black; */
  position: relative;
  left: 0%;
  top: 49%;
  width: 100%;
  height: 1%;
  margin-bottom: -4%;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(0, 0, 0, 0) 5%,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 1) 30%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 50%
  );
}

.css-gauge .css-gauge-needle-center {
  position: absolute;
  top: 46%;
  left: 50%;
  width: 20%;
  height: 20%;
  background: #000;
  border-radius: 100%;
  margin-top: -6.6%;
  margin-left: -8.5%;
  border: 0.18em solid #59d3fe;
}

.css-gauge .css-gauge-circle-wrapper {
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 10%;
  right: 10%;
}
.css-gauge .css-gauge-circle {
  position: absolute;
  text-align: center;
  top: 8%;
  left: 8%;
  width: 84%;
  height: 84%;
  border-radius: 100%;
  background-color: #ffffff;
}

.css-gauge .css-gauge-circle-0-50 {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  /* background-color: #e53b3b; */
  background: linear-gradient(113deg, #f9a3ae 36%, transparent 35%);
}
.css-gauge .css-gauge-circle-50-80 {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  /* background-color: #e53b3b; */
  background: linear-gradient(170deg, #e7e99f 50%, transparent 50%);
}
.css-gauge .css-gauge-circle-80-100 {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  /* background-color: #e53b3b; */
  background: linear-gradient(225deg, #9cf1aa 50%, transparent 50%);
}
