/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiTabPanel-root {
  padding: 0px !important;
}
.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
