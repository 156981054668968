html,
body {
  margin: 0;
  padding: 0;
}

.body {
  display: flex;
  flex-direction: row;
}

.sideBar {
  flex: 0 0 1;
  width: 200px;
  padding: 10px;
  border-right: 1px solid #111;
  height: 100vh;
  background: #e1e1e1;
}

.sideBarItem {
  border: 1px solid #000;
  border-radius: 3px;
  height: 30px;
  padding: 10px;
  margin-top: 10px;
  background: #ccc;
}

.dropZone {
  flex: 0 0 auto;
  height: 20px;
  transition: 200ms all;
}

/* .dropZone.horizontalDrag {
  width: 40px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.dropZone.verticalDrag {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .dropZone:not(.horizontalDrag).isLast {
  flex: 1 1 auto;
} */

.dropZone.active,
.trashDropZone.active {
  background: #bed9e9;
  transition: 100ms all;
  border: 1px dashed rgb(160, 158, 158);
  border-radius: 5px;
  text-align: center;
}

.component {
  height: 60px;
  padding: 10px;
  background: #ffffff;
}

.column {
  border-radius: 10px;
  flex: 1 1 100%;
  padding: 10px;
}

.columns {
  display: flex;
  padding: 20px 0;
}

.columnContainer {
  display: flex;
}

.base {
  padding: 0.5rem 1rem;
  background-color: white;
  cursor: move;
}

.row {
  border: 1px solid red;
  padding: 0;
}

.trashDropZone {
  position: relative;
  text-align: center;
  padding: 20px 0;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  border: 1px solid purple;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 100px;
}
